import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'common/Layout';
import SimpleHeader from 'components/sections/SimpleHeader';
import Footer from 'components/sections/Footer';

import { Container } from 'components/global';

const SEO_DATA = {
  title: 'Careers - ETHGlobal',
  description: 'Help build the Ethereum ecosystem.',
  url: 'http://ethglobal.co/join',
  author: 'ETHGlobal',
  twitter: {
    id: '@ethglobal',
    img: 'https://ethglobal.co/og-hiring.png',
  },
  facebook: {
    id: '',
    img: 'https://ethglobal.co/og-hiring.png',
  },
};

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout socialParams={SEO_DATA}>
      <SimpleHeader />
      <Container style={{ fontSize: '16px', lineHeight: '1.8em' }}>
        <h2>{frontmatter.title}</h2>
        <div
          className="job-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Container>
      <Footer style={{ marginTop: '100px' }} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
